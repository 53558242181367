import FormInput from '@c/forms/controls/FormInput';
import FormLabel from '@c/forms/controls/FormLabel';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateUserByKey } from '@util/firestore/users';
import { logError } from '@util/logError';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '..';
import { BackspaceIcon } from '@heroicons/react/20/solid';
import { CountryDropdown } from '@c/forms/CountryDropdown';

function PhoneForm({
  phone: initialPhone,
  verificationId: initialVerificationId,
  continueFn,
  title = 'Enter Phone Number',
}: {
  phone: string;
  verificationId: string;
  continueFn: () => void;
  title?: string;
}) {
  const [loading, setLoading] = useState(false);
  const { userDoc } = useAuth();
  const [phone, setPhone] = useState(initialPhone);
  const [countryCode, setCountryCode] = useState<string>(
    userDoc?.country.code ?? 'US'
  );
  const [verificationId, setVerificationId] = useState(initialVerificationId);
  const { linkPhone, sendPhoneVerification, user } = useAuth();
  const { showSuccessToast, showErrorToast } = useToastContext();
  const form = useForm<{ code: string }>({
    defaultValues: {
      code: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(
      z.object({ code: z.string().length(6, 'Code is six digits') })
    ),
  });
  const errors = form.formState.errors;

  async function onSubmit(form: { code: string }) {
    try {
      setLoading(true);
      await linkPhone(verificationId, form.code);
      if (user?.phoneNumber) {
        await updateUserByKey(user?.uid, 'phone', user?.phoneNumber);
      }
      showSuccessToast('Phone number verified!');
      continueFn();
    } catch (e) {
      showErrorToast((e as Error).message, 'bottom-center');
      logError(e, `verify-phone onSubmit - ${user?.uid}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="mx-auto flex w-full max-w-[36rem] flex-col gap-[1.6rem] sm:max-w-none">
      <div className="relative gap-[2rem]">
        <form
          className="flex flex-col gap-[2rem]"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          {phone && verificationId ? (
            <>
              <h1 className="text-[2.4rem] font-semibold">
                Enter Verification Code
              </h1>
              <h2 className="text-tos">
                SMS code was sent to your phone {redactPhone(phone)}
              </h2>
              <FormLabel
                required
                value="Code"
                errorMessage={errors.code?.message}
              >
                <FormInput
                  placeholder="123456"
                  autoComplete="off"
                  {...form.register('code')}
                />
              </FormLabel>
              <button
                className="underline"
                type="button"
                onClick={() => {
                  let phoneWithCountry = phone;
                  if (!phoneWithCountry.startsWith('+'))
                    phoneWithCountry = '+1' + phoneWithCountry;
                  const parsed = parsePhoneNumber(phoneWithCountry);
                  if (!parsed) {
                    alert('Unable to parse phone number. Please try again.');
                    return;
                  }
                  sendPhoneVerification(parsed.number)
                    .then((verificationId) => {
                      form.setValue('code', '');
                      setVerificationId(verificationId);
                    })
                    .catch((e) => {
                      logError(e, `verify-phone resend - ${user?.uid}`);
                      showErrorToast(
                        'Unable to send verification code. Please refresh the page and try again or contact support at support@mxlocker.com.'
                      );
                    });
                }}
              >
                Didn&apos;t receive it? Send another code
              </button>
              <Button
                loading={loading}
                text="Continue"
                type="secondary"
                buttonType="submit"
              />
              <Button
                type="tertiary"
                text="Change Phone"
                leadingIcon={<BackspaceIcon width={24} height={24} />}
                onClick={() => {
                  setPhone('');
                  setVerificationId('');
                }}
              />
            </>
          ) : (
            <>
              <h1 className="text-[2rem] font-semibold sm:text-[2.4rem]">
                {title}
              </h1>

              <FormLabel required value="Phone Number">
                <div className="flex gap-[1.6rem]">
                  <CountryDropdown
                    value={countryCode}
                    setValue={setCountryCode}
                  />
                  <FormInput
                    placeholder="555-123-4567"
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    autoComplete="tel"
                  />
                </div>
              </FormLabel>
              <h2 className="text-tos">
                We will send a verification code to this number
              </h2>
              <Button
                text="Send Code"
                loading={loading}
                type="secondary"
                onClick={() => {
                  const code = phone.startsWith('+')
                    ? undefined
                    : userDoc?.country.code;
                  const parsed = parsePhoneNumber(phone, code);
                  if (!parsed) {
                    alert('Unable to parse phone number. Please try again.');
                    return;
                  }
                  setLoading(true);
                  sendPhoneVerification(parsed.number)
                    .then((verificationId) => {
                      setVerificationId(verificationId);
                    })
                    .catch((e) => {
                      if ((e as Error).message.includes('TOO_SHORT')) {
                        showErrorToast(
                          'Phone number is too short. Please try again.'
                        );
                        return;
                      }
                      if ((e as Error).message.includes('ALREADY_IN_US')) {
                        showErrorToast(
                          'Phone number is already in use. Please log in instead.'
                        );
                        return;
                      }
                      logError(e, `verify-phone sendCode - ${user?.uid}`);
                      showErrorToast(
                        'Unable to send verification code. Please refresh the page and try again or contact support at support@mxlocker.com.'
                      );
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }}
              />
            </>
          )}
        </form>
      </div>
    </div>
  );
}

function redactPhone(phone: string) {
  return '***-***-' + phone.slice(-4);
}

export default PhoneForm;
